import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Modal } from "@mui/material";
import Title from "../styled/Title";
import NewPrintBtn from "../utilities/NewPrintBtn";
const Card = styled.div`
  position: relative;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  width: 45%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 30px;
`;
const ExitButton = styled.button`
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0px;
  right: 0;
  border: 0;
  background: transparent
    linear-gradient(
      180deg,
      var(--primary-color) 0%,
      var(--secondary-color) 100%
    )
    0% 0% no-repeat padding-box;
  border-radius: 0px 10px 0px 0px;
  opacity: 1;
  font-size: 25px;
  font-weight: 900;
  letter-spacing: 0px;
  color: #ffffff;
`;
const Text = styled.div`
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 40px;
`;

interface IProps {
  id: string[];
  open: boolean;
  setOpen: (arg: boolean) => void;
  setError: (arg: string) => void;
}
export default function ExcelUploadModal(props: IProps) {
  const { open, setOpen, id, setError } = props;
  const { t } = useTranslation("uploadExcel");
  const { language } = useTranslation().i18n;
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Card>
        <ExitButton onClick={() => setOpen(false)}>X</ExitButton>
        <Title>{t("modal.title")}</Title>
        <Text dir={language === "ar" ? "rtl" : "ltr"}>
          {t("modal.description")} :
          <span style={{ color: "var(--primary-color)" }}>{id}</span>
        </Text>
        <NewPrintBtn
          data={id}
          print="pickup"
          setError={setError}
          setLoading={undefined}
        />
      </Card>
    </Modal>
  );
}
