import React from "react";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;
const Group = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
`;
const TextArea = styled.textarea<{ width?: string; flex?: string }>`
  width: ${(props) => props.width || "300px"};
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #010e3f26;
  opacity: 1;
  padding: 20px;
  border: 0;
  flex: ${(props) => props.flex || "none"};
  font-size: 18px;
  &:focus {
    outline: none;
    border: 60px;
    box-shadow: 0 0 0 0.2rem VAR(--primary-color);
  }
  @media (max-width: 1290px) {
    flex: none;
    width: 300px;
  }
`;

const Label = styled.label`
  align-self: start;
  font-weight: 600;
  font-size: 20px;
  text-transform: capitalize;
  letter-spacing: 0px;
  color: #010e3f;
  opacity: 1;
  @media (max-width: 1440px) {
    font-size: 18px;
  }
  @media (max-width: 1024px) {
    font-size: 15px;
  }
`;
const Error = styled.p`
  color: var(--primary-color);
`;

interface IProps {
  label?: string;
  textArea?: { [x: string]: string | number };
  name: string;
  error?: { message?: string };
  placeholder?: string;
}
export default function TextAreaInputGroup(props: IProps) {
  console.log(typeof props.textArea);
  const { register } = useFormContext();
  return (
    <Container>
      <Group>
        <Label htmlFor={props.label}>{props.label}</Label>
        <TextArea
          id={props.label}
          {...props.textArea}
          {...register(props.name)}
          cols={30}
          rows={7}
        />
      </Group>
      {props.error ? <Error>{props.error.message}</Error> : null}
    </Container>
  );
}
