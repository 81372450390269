import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "../../helpers/AxiosInstance";

type IAuthedUser = {
  SessionID: number;
  UserID: number;
  ClientID: number;
  LoginTime: string;
  IsActive: boolean;
  AccessToken: string;
  LogoutTime: null;
  LoginIP4: string;
  LoginIP6: string;
  BrowserName: string;
  LastActionTime: string;
  LogOutTypeID: null;
  BrowserVersion: string;
  IsMobileDevice: boolean;
  Platform: string;
  ClientName: string;
  AccountNo: string;
  Username: string;
  RoleID: number;
  SubAccountID: number;
  FromCityID: number;
  ProductID: number;
  AllowToOpenShipment: boolean;
};

type InitialState = {
  isLoggedIn?: boolean;
  AuthedUser: any;
  companyData?: string | boolean;
  courierData?: null;
  loading?: boolean;
};
const initialState: InitialState = {
  isLoggedIn: true,
  AuthedUser: null,
  companyData: false,
  courierData: null,
};
// ============================= Tool Kit ================================
export const handleCompanyCode = createAsyncThunk(
  "Auth/handleCompanyCode",
  async ({
    code,
    setLoading,
    setStep,
  }: {
    code: string;
    setLoading: (load: boolean) => void;
    setStep: (step: number) => void;
  }) => {
    const response = await axios.get(
      "https://ahmedaglan.github.io/ClientsJSON/ClientsMPS.json"
    );
    const data = response.data.ClientsList;
    const founded = await data.find(
      (client: { ClientID: number }) =>
        client.ClientID === parseInt(code.trim())
    );
    // send data by action.payload and trim it as founded in fulfilled processing
    if (founded) {
      setStep(2);
      setLoading(false);
      return founded;
    }
  }
);
// =======================================================================
export const checkCompanyData = createAsyncThunk(
  "Auth/checkCompanyData",
  async () => {
    let res = await axios.get("/meta/metadata.json");
    return res.data;
  }
);
// =============================================================================
export const handleUserLogin = createAsyncThunk(
  "auth/handleUserLogin",
  async ({
    data,
    setLoading,
  }: {
    data: { username: string; password: string };
    setLoading: (load: boolean) => void;
  }) => {
    const instance = axiosInstance();
    const { username, password } = data;
    setLoading(true);
    const response = await instance.post("/Login", {
      username,
      password,
    });
    return response.data;
  }
);
// ==========================================================================
export const handleLogout = createAsyncThunk("Auth/handleLogout", async () => {
  const instance = axiosInstance();
  const response = await instance.post("/Logout");
  return response;
});
// ============================================================================
const authSlice = createSlice({
  name: "Auth",
  initialState,
  reducers: {
    checkUser: (state) => {
      const AuthedUser = localStorage.getItem("AuthedUser");
      if (AuthedUser) {
        const authedUserData = JSON.parse(AuthedUser);
        state.AuthedUser = authedUserData;
      } else {
        state.isLoggedIn = false;
      }
    },
    logOut: (state) => {
      state.AuthedUser = null;
      state.isLoggedIn = false;
    },
    checkCompany: (
      state,
      action: PayloadAction<{ setStep: (value: number) => void }>
    ) => {
      const companyData = localStorage.getItem("CompanyData");
      if (companyData) {
        state.companyData = JSON.parse(companyData);
        action.payload.setStep(2);
      } else {
        action.payload.setStep(1);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleUserLogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(handleUserLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.AuthedUser = action.payload;
        localStorage.setItem("AuthedUser", JSON.stringify(action.payload));
        state.isLoggedIn = true;
      })
      .addCase(handleUserLogin.rejected, (state) => {
        state.loading = false;
      })
      .addCase(handleCompanyCode.pending, (state) => {
        state.loading = true;
      })
      .addCase(handleCompanyCode.fulfilled, (state, action) => {
        if (typeof action.payload == "undefined") {
          state.companyData = false;
        } else {
          state.loading = false;
          state.companyData = action.payload;
          localStorage.setItem("CompanyData", JSON.stringify(action.payload));
        }
      })
      .addCase(handleCompanyCode.rejected, (state) => {
        state.loading = false;
      })
      .addCase(handleLogout.pending, (state) => {
        state.loading = true;
      })
      .addCase(handleLogout.fulfilled, (state, action) => {
        state.loading = false;
        state.AuthedUser = action.payload;
        localStorage.removeItem("AuthedUser");
        state.isLoggedIn = false;
      })
      .addCase(handleLogout.rejected, (state) => {
        state.loading = false;
        localStorage.removeItem("AuthedUser");
      })
      .addCase(checkCompanyData.pending, (state) => {
        state.loading = true;
      })
      .addCase(checkCompanyData.fulfilled, (state, action) => {
        if (typeof action.payload === "string" || typeof action.payload) {
          const companyData = localStorage.getItem("CompanyData");
          if (companyData) {
            let companyMetaData = JSON.parse(companyData);
            delete companyMetaData.defined;
            state.companyData = companyMetaData;
          } else {
            if (action.payload.setStep) {
              action.payload.setStep(1);
            }
          }
        } else {
          const metaData = { ...action.payload, defined: true };
          localStorage.removeItem("CompanyData");
          localStorage.setItem("CompanyData", JSON.stringify(metaData));
          state.companyData = metaData;
        }
      })
      .addCase(checkCompanyData.rejected, (state) => {
        const companyData = localStorage.getItem("CompanyData");
        if (companyData) {
          const companyMetaData = JSON.parse(companyData);
          delete companyMetaData.defined;
          state.companyData = companyMetaData;
        }
      });
  },
});

export const { checkCompany, checkUser, logOut } = authSlice.actions;
export default authSlice.reducer;
