import { useRef, useContext } from "react";
import { Box } from "@mui/material";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Toolbar,
  ExcelExport,
  Group,
  Filter,
  Selection,
  Sort,
  Page,
} from "@syncfusion/ej2-react-grids";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { selectedContext } from "../../pages/ShipmentsReport";
import React from "react";

const CustomLink = styled(Link)`
  color: var(--primary-color);
  font-weight: 500;
  text-decoration: none;
`;

interface IProps {
  rows: { [x: string]: string | number };
  currentStatus: string | null;
  lockedPage?: boolean;
}
interface ISelected {
  setSelected: (arg: any) => void;
  selected: string[];
}
// using react context may become more performant than passing state to props
const ReportsTable = (props: IProps) => {
  const { setSelected, selected } = useContext(selectedContext) as ISelected;
  let { rows, currentStatus } = props;
  let isDelivered = currentStatus === "delivered";
  const toolbarOptions = ["ExcelExport", "CsvExport"];
  const { t, i18n } = useTranslation("reports");
  const { language } = i18n;
  const gridInstance = useRef<GridComponent>(null);

  const toolbarClick = (args: { item: { text: string } }) => {
    switch (args.item.text) {
      case "Excel Export":
        gridInstance.current?.excelExport();
        break;
      case "CSV Export":
        gridInstance.current?.csvExport();
        break;
    }
  };
  const handleRowSelection = (e: {
    isHeaderCheckboxClicked: boolean;
    data: { AWB: string };
  }) => {
    if (e.isHeaderCheckboxClicked === true) {
      if (Array.isArray(e.data)) {
        setSelected([
          ...selected,
          ...e.data.map((shipment: { AWB: string }) => shipment.AWB),
        ]);
      } else {
        setSelected([...selected, e.data.AWB]);
      }
    } else {
      setSelected((selected: string[]) => [...selected, e.data.AWB]);
    }
  };
  const handleRowDeSelection = (e: {
    isHeaderCheckboxClicked: boolean;
    data: { AWB: string };
  }) => {
    if (e.isHeaderCheckboxClicked === true) {
      setSelected([]);
    } else {
      setSelected(
        selected.filter((shipment: string) => {
          return shipment !== e.data.AWB;
        })
      );
    }
  };

  return (
    <Box
      sx={{
        "& .e-gridpager": {
          height: "30px",
          display: "flex",
          alignItems: "center",
        },
      }}
    >
      <GridComponent
        enableRtl={language === "ar" ? true : false}
        dataSource={rows}
        rowSelected={handleRowSelection}
        rowDeselected={handleRowDeSelection}
        selectionSettings={{ type: "Multiple" }}
        ref={() => gridInstance}
        toolbar={toolbarOptions}
        filterSettings={{ type: "Excel" }}
        allowSorting
        allowExcelExport
        enableStickyHeader
        allowFiltering
        allowGrouping
        toolbarClick={toolbarClick}
      >
        <ColumnsDirective>
          <ColumnDirective width={70} type="checkbox"></ColumnDirective>
          <ColumnDirective
            template={(e: { AWB: number }) => {
              return <CustomLink to={`/shipment/${e.AWB}`}>{e.AWB}</CustomLink>;
            }}
            field="AWB"
            headerText={t("awb")}
            width="120"
          ></ColumnDirective>
          <ColumnDirective
            field="SpecialInstuctions"
            headerText={t("specialInstructions")}
            width="350"
          ></ColumnDirective>
          <ColumnDirective
            field="ReasonNameA"
            headerText={t("reason")}
            width="150"
          ></ColumnDirective>
          <ColumnDirective
            field="UpdateNotes"
            headerText={t("UpdateNotes")}
            width="150"
          ></ColumnDirective>
          <ColumnDirective
            field="StatusNameA"
            headerText={t("status")}
            width="150"
          ></ColumnDirective>
          <ColumnDirective
            field="FromContactPerson"
            headerText={t("FromContactPerson")}
            width="150"
          ></ColumnDirective>
          <ColumnDirective
            field="AWBvsAWB"
            headerText={t("AWBvsAWB")}
            width="150"
          ></ColumnDirective>
          <ColumnDirective
            field="COD"
            headerText={t("COD")}
            width="150"
          ></ColumnDirective>
          <ColumnDirective
            field="ShipmentFees"
            headerText={t("fees")}
            width="150"
          ></ColumnDirective>
          <ColumnDirective
            field="NetCOD"
            headerText={t("NetCOD")}
            width="150"
          ></ColumnDirective>
          {isDelivered ? (
            <ColumnDirective
              field="DeliveryDate"
              headerText={t("DeliveryDate")}
              width="150"
            ></ColumnDirective>
          ) : (
            ""
          )}
          <ColumnDirective
            field="ToRef"
            headerText={t("ref")}
            width="150"
          ></ColumnDirective>
          <ColumnDirective
            headerText={t("runner")}
            width="240"
            field="runnerData"
          ></ColumnDirective>
          <ColumnDirective
            field="ToConsigneeName"
            headerText={t("consignee")}
            width="150"
            textAlign="Right"
          ></ColumnDirective>
          <ColumnDirective
            field="PickupDate"
            headerText={t("PickupDate")}
            width="150"
            textAlign="Right"
          ></ColumnDirective>
          <ColumnDirective
            field="FromBranch"
            headerText={t("FromBranch")}
            width="150"
            textAlign="Right"
          ></ColumnDirective>
          <ColumnDirective
            field="ToBranch"
            headerText={t("ToBranch")}
            width="150"
            textAlign="Right"
          ></ColumnDirective>

          <ColumnDirective
            field="CurrentBranch"
            headerText={t("CurrentBranch")}
            width="150"
            textAlign="Right"
          ></ColumnDirective>

          <ColumnDirective
            headerText={t("city")}
            field="ToCityName"
            width="120"
          ></ColumnDirective>
          <ColumnDirective
            headerText={t("address")}
            width="150"
            field="ToAddress"
          ></ColumnDirective>
          <ColumnDirective
            headerText={t("phone")}
            field="ToPhone"
            width="150"
          ></ColumnDirective>
          <ColumnDirective
            headerText={t("payment_value")}
            field="PaymentValue"
            width="150"
          ></ColumnDirective>
          <ColumnDirective
            headerText={t("paid")}
            width="120"
            field="PaidToCust"
          ></ColumnDirective>
          <ColumnDirective
            headerText={t("Data", { name: 1 })}
            width="120"
            field="Data1"
          ></ColumnDirective>
          <ColumnDirective
            headerText={t("Data", { name: 2 })}
            width="120"
            field="Data2"
          ></ColumnDirective>
          <ColumnDirective
            headerText={t("Data", { name: 3 })}
            width="120"
            field="Data3"
          ></ColumnDirective>
          <ColumnDirective
            headerText={t("Data", { name: 4 })}
            width="120"
            field="Data4"
          ></ColumnDirective>
          <ColumnDirective
            headerText={t("Data", { name: 5 })}
            width="120"
            field="Data5"
          ></ColumnDirective>
          <ColumnDirective
            headerText={t("Data", { name: 6 })}
            width="120"
            field="Data6"
          ></ColumnDirective>
          <ColumnDirective
            headerText={t("Data", { name: 7 })}
            width="120"
            field="Data7"
          ></ColumnDirective>
          <ColumnDirective
            headerText={t("Data", { name: 8 })}
            width="120"
            field="Data4"
          ></ColumnDirective>
          <ColumnDirective
            headerText={t("Data", { name: 9 })}
            width="120"
            field="Data9"
          ></ColumnDirective>
          <ColumnDirective
            headerText={t("Data", { name: 10 })}
            width="120"
            field="Data10"
          ></ColumnDirective>
        </ColumnsDirective>
        <Inject
          services={[
            Toolbar,
            ExcelExport,
            Group,
            Page,
            Filter,
            Selection,
            Sort,
          ]}
        />
      </GridComponent>
    </Box>
  );
};

export default ReportsTable;
