import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Autocomplete, TextField, Snackbar, Box, Alert } from "@mui/material";
import ConfirmationDialog from "../ConfirmationDialog";
import LabelPreview from "../LabelPreview";
import { useQuery } from "react-query";
import axiosInstance from "../../helpers/AxiosInstance";

const StyledTextField = styled(TextField)`
  position: relative;
  font-size: 13;
  width: 187px;
  font-weight: bold;
  background-color: white;
  box-shadow: 0px 3px 6px #0000005e;
`;

type IParams = {
  data: string[];
  print: string;
  setLoading?: (load: boolean) => void;
  setError?: (err: string) => void;
};

export default function NewPrintBtn({ data, print, setLoading }: IParams) {
  const { language } = useTranslation().i18n;
  const instance = axiosInstance();
  const [label, setLabel] = useState(null);
  const { data: labelData } = useQuery(
    "getLabels",
    async () => {
      const response = await instance.get("GetPDFTemplates");
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  setLoading = setLoading ? setLoading : () => {};
  const { t } = useTranslation("uploadExcel");
  const [value, setValue] = React.useState("");
  const [openPreview, setOpenPreview] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const handleChange = (event: any) => {
    if (data.length === 0) {
      setSnackbarOpen(true);
      return;
    }

    let foundLabel = labelData.find(
      (label: { name: string }) => label.name === event?.innerHTML
    ).templateID;
    const showPreview = async () => {
      setOpenPreview(true);
      setLabel(
        labelData?.find(
          (label: { templateID: number }) => label.templateID === foundLabel
        )
      );
    };
    if (data?.length > 0 || data.toString().length > 0) {
      if (print === "shipments") {
        showPreview().then(() => {
          setValue("");
        });
      } else {
        showPreview().then(() => {
          setValue("");
        });
      }
    }
  };
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };
  return (
    <div>
      <ConfirmationDialog />
      <Autocomplete
        options={labelData ?? []}
        value={value}
        sx={{ direction: `${language === "en" ? "rtl" : "ltr"}` }}
        onChange={(e) => {
          handleChange(e.target);
        }}
        getOptionLabel={(Option: any) => Option.name ?? `${t("export_pdf")}`}
        renderInput={(params) => <StyledTextField {...params} />}
      />
      <LabelPreview
        data={data}
        label={label}
        open={openPreview}
        setOpen={setOpenPreview}
      />
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="error"
          sx={{ width: "100%" }}
        >
          <Box sx={{ px: 2 }}>{t("No_Shipment_Selected")}</Box>
        </Alert>
      </Snackbar>
    </div>
  );
}
