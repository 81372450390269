import React, { useState } from "react";
import {
  Box,
  Collapse,
  List,
  ListItemButton,
  ListItemText,
  IconButton,
  ListItemIcon,
  Popover,
} from "@mui/material";
import { ButtonsContainer, Notification } from "../utilities";
import { useDispatch } from "react-redux";
import Searchbar from "./Searchbar";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import {
  MenuIcon,
  ShipmentIcon,
  PickupsIcon,
  ExportDataIcon,
  AdvancedSearch,
} from "../../assets/icons";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import axiosInstance from "../../helpers/AxiosInstance";
import { Link } from "react-router-dom";
import Translation from "../utilities/Translation";
import { handleLogout } from "../../redux/reducers/authReducer";
const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 520px;
  min-width: 300px;
  height: 480px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
  @media (max-width: 1440px) {
    width: 400px;
  }
  @media (max-width: 425px) {
    min-width: 300px;
  }
`;

const Line = styled.hr`
  border: 1px solid var(--primary-color);
`;

const Button = styled(Link)`
  min-width: 162px;
  height: 50px;
  background: transparent;
  display: grid;
  place-items: center;
  text-align: center;
  font-weight: 700;
  font-family: Cairo;
  font-size: 18px;
  letter-spacing: 0px;
  color: #010e3f;
  text-decoration: none;
  opacity: 1;
  @media (max-width: 1440px) {
    font-size: 18px;
  }
`;
interface IProps {
  RoleID: number;
}
interface IState {
  Auth: { companyData: { Logo: string; ClientID: number } };
}
function MobileLayout({ RoleID }: IProps) {
  const { pathname } = useLocation();
  const instance = axiosInstance();
  const { data, refetch } = useQuery(
    "notifications",
    async () => {
      const { data } = await instance.get("/GetActiveNotifications");
      return data;
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 1000,
      cacheTime: 3000,
    }
  );
  const dispatch = useDispatch();
  const { t } = useTranslation("layout");
  const [open, setOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const openNotification = Boolean(anchor);
  const handleNotificationClick = (event: React.BaseSyntheticEvent) => {
    setAnchor(event.currentTarget);
  };
  const { Logo } = useSelector((state: IState) => ({
    Logo:
      state.Auth.companyData.Logo ||
      `https://ahmedaglan.github.io/ClientsJSON/ClientImages/${
        state.Auth.companyData.ClientID || "999999"
      }/logo.png`,
  }));
  const handleLogoutClick = () => {
    setOpen(false);
    dispatch(handleLogout());
  };
  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        maxHeight: "100vh",
        overflow: "auto",
        zIndex: 243,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "var(--gradient)",
          borderBottom: "2px solid #EEE",
        }}
      >
        {RoleID === 2 && (
          <Box
            sx={{ display: "grid", placeItems: "center" }}
            onClick={() => setOpen(!open)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#FFF"
              height="48"
              width="48"
            >
              <path d="M6 36V33H42V36ZM6 25.5V22.5H42V25.5ZM6 15V12H42V15Z" />
            </svg>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          {RoleID === 1 && (
            <Box
              onClick={handleLogoutClick}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "7px",
              }}
            >
              <Box
                sx={{
                  height: "30px",
                  width: "30px",
                  position: "relative",
                  marginInlineStart: "5px",
                }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path
                    fill="#FFF"
                    d="M160 96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96C43 32 0 75 0 128V384c0 53 43 96 96 96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H96c-17.7 0-32-14.3-32-32l0-256c0-17.7 14.3-32 32-32h64zM504.5 273.4c4.8-4.5 7.5-10.8 7.5-17.4s-2.7-12.9-7.5-17.4l-144-136c-7-6.6-17.2-8.4-26-4.6s-14.5 12.5-14.5 22v72H192c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32H320v72c0 9.6 5.7 18.2 14.5 22s19 2 26-4.6l144-136z"
                  />
                </svg>
              </Box>
            </Box>
          )}

          {RoleID === 2 && (
            <>
              <IconButton
                sx={{
                  background: "transparent",
                  position: "relative",
                  "&::before": {
                    content: "''",
                    position: "absolute",
                    top: "0",
                    right: "0",
                    width: "15px",
                    borderRadius: "50%",
                    height: "15px",
                    backgroundColor: data?.notifications.length
                      ? "white"
                      : "transparent",
                  },
                }}
                onClick={handleNotificationClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 29.255 33"
                >
                  <path
                    d="M32.288,23.088,29.25,20.015V14.78A11.412,11.412,0,0,0,19.125,3.4V1.125h-2.25V3.4A11.5,11.5,0,0,0,6.75,14.78v5.234L3.712,23.088a1.041,1.041,0,0,0-.337.8V27.3a1.076,1.076,0,0,0,.307.827,1.05,1.05,0,0,0,.818.311h7.875a5.625,5.625,0,1,0,11.25,0H31.5a1.05,1.05,0,0,0,.818-.311,1.076,1.076,0,0,0,.307-.827V23.884A1.041,1.041,0,0,0,32.288,23.088ZM18,31.849a3.4,3.4,0,0,1-3.375-3.414h6.75A3.4,3.4,0,0,1,18,31.849Z"
                    transform="translate(-3.373 -1.125)"
                    fill="white"
                  />
                </svg>
              </IconButton>
            </>
          )}
          <Translation isMobile />
          <Box>{!pathname.includes("cshome") && <Searchbar />}</Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            placeItems: "center",
            width: "50px",
            height: "50px",
          }}
        >
          <Box
            component="img"
            src={Logo}
            sx={{
              width: "100%",
              top: 3,
              position: "relative",
              "@media (max-width: 330px)": {
                display: "none",
              },
            }}
          />
        </Box>
      </Box>
      <Collapse in={open} sx={{ background: "var(--gradient)" }}>
        <List
          sx={{ width: "100%" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItemButton
            onClick={() => setOpen(false)}
            component={NavLink}
            to="/"
            sx={{
              textAlign: "right",
              paddingRight: "30px",
              py: 1,
              marginBottom: 2,
              color: "white",
            }}
          >
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <img src={MenuIcon} alt="" />
            </ListItemIcon>
            <ListItemText primary={t("Sidebar.Menu")} />
          </ListItemButton>
          <ListItemButton
            onClick={() => setOpen(false)}
            component={NavLink}
            to="/reports/shipments"
            sx={{
              textAlign: "right",
              paddingRight: "30px",
              color: "white",
              gap: "2px",
              py: 2,
            }}
          >
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <img src={ShipmentIcon} alt="" />
            </ListItemIcon>
            <ListItemText primary={t("Sidebar.Shipments")} />
          </ListItemButton>
          <ListItemButton
            onClick={() => setOpen(false)}
            component={NavLink}
            to="/reports/pickups"
            sx={{
              textAlign: "right",
              paddingRight: "30px",
              gap: "2px",
              py: 2,
              color: "white",
            }}
          >
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <img src={PickupsIcon} alt="" />
            </ListItemIcon>

            <ListItemText primary={t("Sidebar.Pickups")} />
          </ListItemButton>
          <ListItemButton
            onClick={() => setOpen(false)}
            component={NavLink}
            to="/reports/export"
            sx={{
              color: "white",
              textAlign: "right",
              paddingRight: "19px",
              gap: "2px",
              py: 2,
            }}
          >
            <ListItemIcon sx={{ minWidth: "35px" }}>
              <img src={ExportDataIcon} alt="" />
            </ListItemIcon>
            <ListItemText primary={t("Sidebar.Reports_ExportData")} />
          </ListItemButton>
          <ListItemButton
            onClick={() => setOpen(false)}
            component={NavLink}
            to="/multi-search"
            sx={{
              textAlign: "right",
              py: 2,
            }}
          >
            <ListItemIcon>
              <img src={AdvancedSearch} alt="" />
            </ListItemIcon>
            <ListItemText
              sx={{ color: "white" }}
              primary={t("Sidebar.Advanced_Search")}
            />
          </ListItemButton>
          <ListItemButton
            onClick={() => setOpen(false)}
            component={NavLink}
            to="/new-shipment"
            sx={{
              textAlign: "right",
              color: "white",
              paddingRight: "30px",
              gap: "2px",
              py: 2,
            }}
          >
            <ListItemText primary={t("Navbar.add_shipment")} />
          </ListItemButton>
          <ListItemButton
            onClick={() => setOpen(false)}
            component={NavLink}
            to="/new-pickup"
            sx={{
              textAlign: "right",
              color: "white",
              paddingRight: "30px",
              gap: "2px",
              py: 2,
            }}
          >
            <ListItemText primary={t("Navbar.add_pickup")} />
          </ListItemButton>
          <ListItemButton
            onClick={() => setOpen(false)}
            component={NavLink}
            to="/tickets"
            sx={{
              textAlign: "right",
              color: "white",
              paddingRight: "30px",
              gap: "2px",
              py: 2,
            }}
          >
            <ListItemText primary={t("Sidebar.Tickets")} />
          </ListItemButton>
          <ListItemButton
            onClick={() => setOpen(false)}
            component={NavLink}
            to="/upload"
            sx={{
              textAlign: "right",
              color: "white",
              paddingRight: "30px",
              gap: "2px",
              py: 2,
            }}
          >
            <ListItemText primary={t("Navbar.upload_excel")} />
          </ListItemButton>
          <ListItemButton
            component={NavLink}
            to="/change-password"
            sx={{
              textAlign: "right",
              color: "white",
              paddingRight: "30px",
              gap: "2px",
              py: 2,
            }}
          >
            <ListItemText primary={t("Navbar.change_password")} />
          </ListItemButton>
          <ListItemButton
            onClick={() => handleLogoutClick()}
            sx={{
              textAlign: "right",
              color: "white",
              paddingRight: "30px",
              gap: "2px",
              py: 2,
            }}
          >
            <ListItemText primary={t("Navbar.signout")} />
          </ListItemButton>
        </List>
      </Collapse>
      <Popover
        open={openNotification}
        anchorEl={anchor}
        onClick={() => {
          refetch();
          setAnchor(null);
        }}
        onClose={() => setAnchor(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{ fontFamily: "Cairo" }}
      >
        <Card>
          <div dir="rtl">
            {data
              ? data.notifications.slice(0, 3).map(
                  (
                    notification: {
                      Serial: number;
                      AWB: string;
                      Body: string;
                      Title: string;
                    },
                    index: number
                  ) => (
                    <Notification
                      key={index}
                      AWB={notification.AWB}
                      Body={notification.Body}
                      Status={notification.Title}
                      Serial={notification.Serial}
                      notificationIndex={undefined}
                      notifications={undefined}
                      setNotifications={undefined}
                      primary={undefined}
                    />
                  )
                )
              : null}
            <Line />
            <ButtonsContainer
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <Button to="/notifications" onClick={() => setAnchor(null)}>
                {t("Navbar.all_notifications")}
              </Button>
            </ButtonsContainer>
          </div>
        </Card>
      </Popover>
    </Box>
  );
}
export default MobileLayout;
