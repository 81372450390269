import {
  SET_CONFIRM,
  SET_FROM_INDEX,
  SET_OPEN,
  SET_PRINTED,
  SET_TO_INDEX,
} from "../actions/initial";
const initialReducer = (
  state = {
    confirm: null,
    fromIndex: null,
    toIndex: null,
    open: false,
    printed: null,
  },
  action: any
) => {
  switch (action.type) {
    case SET_CONFIRM:
      return { ...state, confirm: action.payload };
    case SET_OPEN:
      return { ...state, open: action.payload };
    case SET_FROM_INDEX:
      return { ...state, fromIndex: action.payload };
    case SET_TO_INDEX:
      return { ...state, toIndex: action.payload };
    case SET_PRINTED:
      return {
        ...state,
        printed: action.payload,
      };
    default:
      return state;
  }
};
export default initialReducer;
